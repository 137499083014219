
import { dispose, graphic } from "echarts";
import useEcharts from "@/hooks/Echarts";
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
export default defineComponent({
  name: "SalesChart",
  setup() {
    const loading = ref(true);
    const salesChart = ref<HTMLDivElement | null>(null);
    const init = () => {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [{
          name: 'Funnel',
          type: 'funnel',
          left: '15%',
          top: 60,
          bottom: 60,
          width: '70%',
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'descending',
          gap: 20,
          label: {
            show: true,
            position: 'left',
            fontSize: 12
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: 'solid'
            }
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1
          },
          emphasis: {
            label: {
              fontSize: 20
            }
          },
          data: [
            {
              value: 25,
              name: '迭代中',
              itemStyle: {
                color: '#89A9F9', // 设置这一块的颜色为红色
              },
            },
            {
              value: 50,
              name: '迭代中',
              itemStyle: {
                color: '#38BFDB', // 设置这一块的颜色为红色
              },
            },
            {
              value: 75,
              name: '迭代中',
              itemStyle: {
                color: '#0BC7D3', // 设置这一块的颜色为红色
              },
            },
            {
              value: 100,
              name: '迭代中',
              itemStyle: {
                color: '#99A6B6', // 设置这一块的颜色为红色
              },
            }
          ]
        },
        {
          name: 'Funnel',
          type: 'funnel',
          left: '15%',
          top: 60,
          bottom: 60,
          width: '70%',
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'descending',
          gap: 20,
          label: {
            show: true,
            position: 'inside',
            formatter: '{c}',
            color: '#fff'
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: 'solid'
            }
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1
          },
          emphasis: {
            label: {
              fontSize: 20
            }
          },
          data: [
            {
              value: 25,
              name: '购买量',
              itemStyle: {
                color: '#89A9F9', // 设置这一块的颜色为红色
              },
            },
            {
              value: 50,
              name: '点击量',
              itemStyle: {
                color: '#38BFDB', // 设置这一块的颜色为红色
              },
            },
            {
              value: 75,
              name: '用户注册量',
              itemStyle: {
                color: '#0BC7D3', // 设置这一块的颜色为红色
              },
            },
            {
              value: 100,
              name: '新浏览量',
              itemStyle: {
                color: '#99A6B6', // 设置这一块的颜色为红色
              },
            }
          ],
          z: 100
        }]
      };


      setTimeout(() => {
        loading.value = false;
        nextTick(() => {
          useEcharts(salesChart.value as HTMLDivElement).setOption(option);
        });
      }, 100);
    };
    const updateChart = () => {
      useEcharts(salesChart.value as HTMLDivElement).resize();
    };
    onMounted(init);
    return {
      loading,
      salesChart,
      updateChart,
    };
  },
});
